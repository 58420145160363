import React from "react"
import { get } from "lodash"
import { linkResolver } from "../../utils/linkResolver"
import { Strings } from "../../utils/strings"
import Image from "./Image"

const ArticleCard = ({ item, index }) => {
  // console.log('[ArticleCard.js] item', item)

  const title = get(item, "title[0].text", "Title placeholder")
  const intro = get(item, "intro[0].text")
  const meta = get(item, "_meta")
  const lang = get(meta, "lang")
  const linkUrl = linkResolver(meta)

  const date = new Date(get(item, "date"))

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  const dateText = date.toLocaleDateString(lang, dateOptions)
  const main_image = get(item, "main_image")

  const allStrings = Strings(meta)
  // console.log('[renderCard] allStrings', allStrings)

  return (
    <div className={"product_card product_card-article"} key={index}>
      <a href={linkUrl}>
        <div className="product_card__top">
          <h2 className="product_card__main_info__title h3">{title}</h2>
          <h6 className="p-medium">{dateText}</h6>
        </div>

        <div className="product_card__cover">
          {/* <img src={main_image?.url} alt={main_image?.alt ?? title} /> */}
          <Image image={main_image} width={500} />
        </div>

        <div className="product_card__main_info">
          <p className="p-medium font-book">{intro}</p>
        </div>

        <div className="product_card__buttons">
          <button
            href={linkUrl}
            className="product_card__button btn-secondary white small w-arrow"
          >
            {allStrings?.cards_article_card_button ?? "Read article"}
          </button>
        </div>
      </a>
    </div>
  )
}

export default ArticleCard
