import React, { useMemo } from 'react'
import { get } from 'lodash'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'
// import '../../../node_modules/uikit/dist/js/uikit.js'
// import { useWindowSizes } from '@hooks/window'

const EditorialBanner = ({ data, index, page }) => {

  // const { isIpadPro, isIpad, isIphonePlus, isDesktop } = useWindowSizes()
  
  // const { isIpadPro } = useWindowSizes()
  // const videoRef = useRef(null)

  // console.log('[EditorialBanner.js] data', data)

  // const id = useMemo(() => (
  //   get(data, 'primary.id[0].text', '1231232')
  // ), [data])

  const title = useMemo(() => (
    get(data, 'primary.title[0].text', 'title placeholder')
  ), [data])

  const text = useMemo(() => (
    get(data, 'primary.text[0].text', 'text placeholder')
  ), [data])

  const image = useMemo(() => (
    get(data, 'primary.image')
  ), [data])

  return(
    <section
      key={index}
      className={"editorial_banner"}>
      
      <div className="editorial_banner__image">
        <img src={image.url} alt={image.alt ?? title} />
      </div>

      <div className="editorial_banner__text">
        <h4>
          {title}
        </h4>
        <p className="p-large font-book">
          {text}
        </p>
      </div> 
      
    </section>
  )  
}

export default EditorialBanner
