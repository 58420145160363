import React, { useMemo } from 'react'
import { get } from 'lodash'
import { linkResolver } from '../../utils/linkResolver'

const IlustrationEditorialBanner = ({ data, index, page }) => {

  // const { isIpadPro } = useWindowSizes()
  // const videoRef = useRef(null)

  // console.log('[IlustrationEditorialBanner.js] data', data)

  // const id = useMemo(() => (
  //   get(data, 'primary.id[0].text', '1231232')
  // ), [data])

  const title = useMemo(() => (
    get(data, 'primary.title[0].text', 'title placeholder')
  ), [data])

  const text = useMemo(() => (
    get(data, 'primary.text[0].text', 'text placeholder')
  ), [data])

  const button_text = useMemo(() => (
    get(data, 'primary.button_text[0].text')
  ), [data])

  // const button_url = useMemo(() => (
  //   get(data, 'primary.button_text[0].text')
  // ), [data])

  const linkUrlType = get(data, 'primary.button_url._linkType')
  // console.log('[IlustrationEditorialBanner.js] linkUrlType', linkUrlType)
  let linkUrl = ""
  if (linkUrlType === 'Link.document') {
    linkUrl = linkResolver(get(data, 'primary.button_url'))
  } else {
    linkUrl = get(data, 'primary.button_url.url')
  }

  return(
    <section
      key={index}
      className={"ilustration_editorial_banner"}>

      <div className="row">
        <div className="col col6 ilustration_editorial_banner__space_col"></div>
        <div className="col col4 ilustration_editorial_banner__text_col">
          <div className="ilustration_editorial_banner__box">
            <h5 className="h3">{title}</h5>
            <p className="p-large font-book">
              {text}
            </p>
            { button_text ? 
              <a href={linkUrl} className="btn-secondary black small w-arrow">
                {button_text}
              </a> 
            : ''}
            
          </div>
        </div>
      </div>
      
      <div className="ilustration_editorial_banner__bg_image">
        <img src="https://ocafi.cdn.prismic.io/ocafi/7dde8cca-5ffc-45c1-aa3c-779a5f549a91_illustration_outline_banner.svg" alt="" />
      </div>
      
    </section>
  )  
}

export default IlustrationEditorialBanner
