import React, { useMemo } from 'react'
import { get } from 'lodash'
// import scrollTo from 'gatsby-plugin-smoothscroll'
// import Scrollspy from 'react-scrollspy'
// import { useWindowSizes } from '@hooks/window'
// import { Strings } from '../../utils/strings'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'
// import '../../../node_modules/uikit/dist/js/uikit.js'

const BlogHeader = ({ index, page }) => {

  // const allStrings = Strings(page)

  const title = useMemo(() => (
    get(page, 'title[0].text', 'title placeholder')
  ), [page])

  const intro = useMemo(() => (
    get(page, 'intro[0].text', 'intro placeholder')
  ), [page])

  return(
    <>
      <section
        key={index}
        className={"plp_header plp_header-blog"}>
        
        <div className="row row-justify">
          <div className="col col4 plp_header__col_text">
            <h1 className="h3">{title}</h1>
            <div className="plp_header__info">
              <p className="p-large font-book">
                {intro}
              </p>
            </div>
          
          </div>
        </div>
      </section>
    </>
  )  
}

export default BlogHeader
