import React, { useMemo } from 'react'
import { get } from 'lodash'
import { Strings } from '../../utils/strings'
import ArticleCard from './ArticleCard'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'

const BlogArticles = ({ index, page, articles }) => {


  // console.log('[PlpCoffees.js] toggleSampleForm', toggleSampleForm)


  const parsedArticles = useMemo(() => {
    if (!articles) {
      return null
    }

    return articles.map((a) => {
      const node = a.node

      return {
        title: get(node, 'title'),
        _meta: get(node, '_meta'),
        slug: get(node, '_meta.uid'),
        date: new Date(get(node, 'date')),
        main_image: get(node, 'main_image'),
        intro: get(node, 'intro'),
      }
    }).sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
  }, [articles])

  const allStrings = Strings(page)

  return(
    <section
      key={index}
      className={"plp_all_coffees"}>

      <div className="list_product_card">

        {parsedArticles.map((item, index) => (
          <div className="list_product_card__col list_product_card__col-article" key={index}>
            <ArticleCard item={item} index={index} />
          </div>
        ))}

        {/* {parsedArticles.map((item, index) => (
          <div className="list_product_card__col list_product_card__col-article" key={index}>
            <ArticleCard item={item} index={index} />
          </div>
        ))}

        {parsedArticles.map((item, index) => (
          <div className="list_product_card__col list_product_card__col-article" key={index}>
            <ArticleCard item={item} index={index} />
          </div>
        ))}

        {parsedArticles.map((item, index) => (
          <div className="list_product_card__col list_product_card__col-article" key={index}>
            <ArticleCard item={item} index={index} />
          </div>
        ))} */}

      </div>

      {parsedArticles.length === 0 ?
        <div className="plp_all_coffees__zero_reults">
          <h4>{allStrings?.main_no_results_found ?? 'No results found.'}</h4>
        </div>
      : null}
      
    </section>
  )
}

export default BlogArticles
