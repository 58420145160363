import React, { useMemo } from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import EditorialBanner from "../components/slices/EditorialBanner"
import IlustrationEditorialBanner from "../components/slices/IlustrationEditorialBanner"
// import PlpCoffees from '../components/slices/PlpCoffees'
// import { useQueryParam, NumberParam, StringParam } from "use-query-params"
// import { getFilter } from '../utils/getFilter'
// import scrollTo from 'gatsby-plugin-smoothscroll'
// import PdpRequestSample from '../components/slices/PdpRequestSample'
import BlogHeader from "../components/slices/BlogHeader"
import BlogArticles from "../components/slices/BlogArticles"

// Display the title, date, and content of the Post
const BlogBody = ({ page, articles }) => {
  // console.log('products', products)

  const renderContainer = (container, index) => {
    // console.log('container', container.type)

    if (container && container.type === "editorial__image___text") {
      return <EditorialBanner key={index} data={container} index={index} />
    }

    if (container && container.type === "illustration_banner") {
      return (
        <IlustrationEditorialBanner
          key={index}
          data={container}
          index={index}
        />
      )
    }

    return []
  }

  if (!page) return null

  return (
    <section>
      <div className="containerGlobal">
        <BlogHeader page={page} />
        <BlogArticles articles={articles} page={page} />

        {page &&
          page?.body?.map((container, index) =>
            renderContainer(container, index)
          )}
      </div>
    </section>
  )
}

export default props => {
  if (!props) return null

  const doc = props.data.prismic.allBlogs.edges[0]
  const allArticles = props.data.prismic.allArticles.edges

  if (!doc) return null

  const meta = doc.node._meta

  const seoTitle = useMemo(() => get(doc, "node.seo_title[0].text"), [doc])

  const seoText = useMemo(() => get(doc, "node.seo_meta[0].text"), [doc])

  const seoImage = useMemo(() => get(doc, "node.seo_image.url"), [doc])

  return (
    <Layout pathname={props.path} meta={meta}>
      <SEO
        title={seoTitle ? seoTitle : "Transparent, Sustainable and Direct"}
        description={seoText}
        image={seoImage}
      />
      <BlogBody page={doc.node} articles={allArticles} />
    </Layout>
  )
}

export const query = graphql`
  query allBlogs($lang: String, $uid: String) {
    prismic {
      allBlogs(lang: $lang, uid: $uid) {
        edges {
          node {
            _meta {
              uid
              lang
              type
              alternateLanguages {
                lang
                uid
                type
              }
            }
            title
            intro
          }
        }
      }
      allArticles(lang: $lang) {
        edges {
          node {
            _meta {
              uid
              lang
              type
            }
            title
            main_image
            intro
            date
          }
        }
      }
    }
  }
`
